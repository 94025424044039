import { Routes as Switch, Route } from 'react-router-dom'
import IpfsRouter from 'ipfs-react-router'

import Home from 'pages/Home'
import Profile from 'pages/Profile/Profile'

import * as Routes from 'constants/Routes'

import './App.css'

const App = () => {

  return (
    <div className='App'>
      <main className='main'>
        <IpfsRouter>
          <Switch>
            <Route exact path={Routes.PROFILE} element={<Profile />} />
            <Route exact path={Routes.HOME} element={<Home />} />
          </Switch>
        </IpfsRouter>
      </main>
    </div>
  )
}

export default App
