import React, { useEffect, useState } from 'react'
import { ethers } from 'ethers'
import { Link } from 'react-router-dom'

import {
  Typography,
  Container,
  Avatar,
  IconButton
} from '@mui/material'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faGlobe, faLocationPin } from '@fortawesome/free-solid-svg-icons'

import {
  faFacebook,
  faTwitter,
  faDiscord,
  faInstagram,
  faTelegram,
  faLinkedin,
  faGithub
} from "@fortawesome/free-brands-svg-icons"

const providerUrl = 'https://cloudflare-eth.com/'
const provider = new ethers.providers.JsonRpcProvider(providerUrl)

const useENS = (ensName) => {
  const [isLoading, setIsLoading] = useState(true)
  const [name, setName] = useState(null)
  const [address, setAddress] = useState(null)
  const [avatar, setAvatar] = useState(null)
  const [email, setEmail] = useState(null)
  const [location, setLocation] = useState(null)
  const [description, setDescription] = useState(null)
  // const [content, setContent] = useState(null)

  const [url, setUrl] = useState(null)
  const [twitter, setTwitter] = useState(null)
  const [discord, setDiscord] = useState(null)
  const [telegram, setTelegram] = useState(null)
  const [instagram, setInstagram] = useState(null)
  const [facebook, setFacebook] = useState(null)
  const [github, setGithub] = useState(null)
  const [linkedin, setLinkedin] = useState(null)
  const [reddit, setReddit] = useState(null)
  const [peepeth, setPeepeth] = useState(null)
  const [links, setLinks] = useState([])

  // display - a canonical display name for the ENS name this MUST match the ENS name when its case is folded, and clients should ignore this value if it does not (e.g. "ricmoo.eth" could set this to "RicMoo.eth")
  // keywords - A list of comma-separated keywords, ordered by most significant first clients that interpresent this field may choose a threshold beyond which to ignore
  // mail - A physical mailing address
  // notice - A notice regarding this name
  // phone - A phone number as an E.164 string
  // com.peepeth - a Peepeth usernamee
  // io.keybase - a Keybase username

  useEffect(() => {
    const fetchUser = async () => {
      try {
        // const provider = new ethers.providers.getDefaultProvider()
        // const ensName = await provider.lookupAddress(getAddress)
        const getAddress = await provider.resolveName(ensName)
        const resolver = ensName ? await provider.getResolver(ensName) : null

        if (resolver) {
          const getName = await resolver.getText('name')
          const getAvatar = await resolver.getAvatar()

          // TODO:
          // check if its ipfs and use this
          // https://ipfs.io/ipfs/QmYAuxdjhQ3WWr7dr3n9FhPCw8GcPyZZaBFGx3sFUMcbfc/

          const getUrl = await resolver.getText('url')
          const getEmail = await resolver.getText('email')
          const getLocation = await resolver.getText('location')
          const getDescription = await resolver.getText('description')

          let handleTwitter = await resolver.getText('vnd.twitter')
          if (!handleTwitter) {
            handleTwitter = await resolver.getText('com.twitter')
          }

          let handleGithub = await resolver.getText('vnd.github')
          if (!handleGithub) {
            handleGithub = await resolver.getText('com.github')
          }

          // TODO
          // add twitch
          // add youtube
          // add tiktok
          // add paypal
          // add calendy
          // add premint
          // add opensea

          // think in a way to add multiple sites
          // website,website1,website2
          // url1: website / url2: website

          const getTwitter = handleTwitter
          const getGithub = handleGithub
          const getTelegram = await resolver.getText('org.telegram')
          const getDiscord = await resolver.getText('com.discord')
          const getLinkedin = await resolver.getText('com.linkedin')
          const getInstagram = await resolver.getText('com.instagram')
          const getFacebook = await resolver.getText('com.facebook')
          const getReddit = await resolver.getText('com.reddit')
          const getPeepeth = await resolver.getText('com.peepeth')
          const getLinks = await resolver.getText('links') || []

          // personal
          setName(getName)
          setAddress(getAddress)
          setAvatar(getAvatar)
          setUrl(getUrl)
          setEmail(getEmail)
          setLocation(getLocation)
          setDescription(getDescription)
          // setContent(getContent)

          // social media
          setTwitter(getTwitter)
          setDiscord(getDiscord)
          setInstagram(getInstagram)
          setFacebook(getFacebook)
          setTelegram(getTelegram)
          setLinkedin(getLinkedin)
          setGithub(getGithub)
          setReddit(getReddit)
          setPeepeth(getPeepeth)
          setLinks(getLinks)
        }
      } finally {
        setIsLoading(false)
      }
    }
    fetchUser()
  }, [ensName])

  return {
    name,
    address,
    avatar,
    url,
    email,
    location,
    description,
    // content,
    twitter,
    discord,
    instagram,
    facebook,
    telegram,
    linkedin,
    github,
    reddit,
    peepeth,
    links,
    isLoading
  }
}

const styles = {
  link: {
    cursor: 'pointer'
  },
  description: {
    margin: '16px',
    fontSize: '15px',
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    maxWidth: '680px',
    textAlign: 'center'
  },
  avatar: {
    width: '100px',
    height: '100px',
    marginBottom: '16px',
  },
  button: {
    width: '100%',
    color: 'white',
    border: '1px solid white',
    borderRadius: '8px',
    marginBottom: '16px',
    padding: '16px',
  },
  iconMargin: {
    marginRight: '5px'
  },
  location: {
    color: 'white',
    fontSize: '0.875rem',
  },
  needSupport: {
    position: 'fixed',
    bottom: '16px'
  }
}

const Profile = () => {
  const [username] = useState(window.location.pathname.replace(/\//g, ''))

  const {
    name,
    address,
    avatar,
    url,
    email,
    location,
    description,
    twitter,
    discord,
    instagram,
    facebook,
    telegram,
    linkedin,
    github,
    links,
    isLoading
  } = useENS(username)

  if (isLoading) {
    return (
      <p>loading...</p>
      // <PageLoader />
    )
  }

  if (!address) {
    return (
      <p>Provide a valid ens name</p>
    )
  }

  return (
    <Container
      component='main'
      style={styles.container}
    >
      {!isLoading && (
        <React.Fragment>
          <Avatar
            style={styles.avatar}
            alt={username}
            src={avatar?.url || ''}
          />
          <Typography
            component='h1'
            variant='h5'
          >
            {name}
          </Typography>

          {email && (
            <Link
              to='#'
              style={{ color: 'white' }}
              onClick={(e) => {
                window.location.href = `mailto:${email}`
                e.preventDefault()
              }}
            >
              {email}
            </Link>
          )}

          {location && (
            <IconButton
              style={styles.location}
              aria-label='Location'
              size='small'
            >
              <FontAwesomeIcon
                style={styles.iconMargin}
                icon={faLocationPin}
              />
               {location}
            </IconButton>
          )}

          {description && (
            <Typography
              style={styles.description}
              variant='body2'
            >
              {description}
            </Typography>
          )}

          {url && (
            <IconButton
              onClick={() => {
                window.open(url, '_blank', 'noreferrer')
              }}
              style={styles.button}
              aria-label='Webpage'
              size='small'
            >
              <FontAwesomeIcon
                style={styles.iconMargin}
                icon={faGlobe}
              />
              {/*{url}*/}
              Website
            </IconButton>
          )}

          {links.length > 0 &&
            links.map((link, index) => (
              <IconButton
                key={index}
                onClick={() => {
                  window.open(url, '_blank', 'noreferrer')
                }}
                style={styles.button}
                aria-label='Webpage'
                size='small'
              >
                {link}
              </IconButton>
            ))
          }

          <div>
            {twitter && (
              <IconButton
                onClick={() => {
                  window.open(`http://twitter.com/${twitter}`, '_blank', 'noreferrer')
                }}
                style={{ color: 'white' }}
                aria-label='Twitter'
                size='medium'
              >
                <FontAwesomeIcon icon={faTwitter} />
              </IconButton>
            )}

            {instagram && (
              <IconButton
                onClick={() => {
                  window.open(`http://instagram.com/${instagram}`, '_blank', 'noreferrer')
                }}
                style={{ color: 'white' }}
                aria-label='Instagram'
                size='medium'
              >
                <FontAwesomeIcon icon={faInstagram} />
              </IconButton>
            )}

            {facebook && (
              <IconButton
                onClick={() => {
                  window.open(`http://facebook.com/${facebook}`, '_blank', 'noreferrer')
                }}
                style={{ color: 'white' }}
                aria-label='Facebook'
                size='medium'
              >
                <FontAwesomeIcon icon={faFacebook} />
              </IconButton>
            )}

            {telegram && (
              <IconButton
                style={{ color: 'white' }}
                aria-label='Telegram'
                size='medium'
              >
                <FontAwesomeIcon icon={faTelegram} />
              </IconButton>
            )}

            {linkedin && (
              <IconButton
                onClick={() => {
                  window.open(`http://linkedin.com/in/${linkedin}`, '_blank', 'noreferrer')
                }}
                style={{ color: 'white' }}
                aria-label='Linkedin'
                size='medium'
              >
                <FontAwesomeIcon icon={faLinkedin} />
              </IconButton>
            )}

            {github && (
              <IconButton
                onClick={() => {
                  window.open(`http://github.com/${github}`, '_blank', 'noreferrer')
                }}
                style={{ color: 'white' }}
                aria-label='Github'
                size='medium'
              >
                <FontAwesomeIcon icon={faGithub} />
              </IconButton>
            )}

            {discord && (
              <IconButton
                onClick={() => {
                  window.open(`http://discordapp.com/users/${discord}`, '_blank', 'noreferrer')
                }}
                style={{ color: 'white' }}
                aria-label='Linkedin'
                size='medium'
              >
                <FontAwesomeIcon icon={faDiscord} />
              </IconButton>
            )}
          </div>

          <div
            style={styles.needSupport}
          >
            <Typography
              style={{
                marginTop: 30,
                marginBottom: 0
              }}
              variant='caption'
              display='block'
              gutterBottom
            >
              Need support? Twitter @ragnacoreB
            </Typography>

            {/*<Typography
              variant='caption'
              display='block'
              gutterBottom
            >
              Buy me a coffee - jumpto.eth
            </Typography>*/}
          </div>
        </React.Fragment>
      )}
    </Container>
  )
}

export default Profile
